import React, { ReactElement } from 'react';
import { Tabs as TabsComponent } from '@student-coin/coinpaper-components';
import { TabsProps } from './Tabs.types';
import { NextLink } from './NextLink/NextLink';

export const Tabs = ({
  items,
  activeTab,
  onActiveTabChange,
  variant,
  asNextLink = true,
  shallowNavigation = true,
}: TabsProps): ReactElement => {
  const anchorComponent = asNextLink ? NextLink : null;

  return (
    <TabsComponent
      items={items}
      anchorComponent={anchorComponent}
      activeTab={activeTab}
      shallowNavigation={shallowNavigation}
      onActiveTabChange={onActiveTabChange}
      variant={variant}
    />
  );
};
