import { FunctionComponent } from 'react';
import { Label } from './Heading.styles';

export const Heading: FunctionComponent<unknown> = ({ children }) => {
  return (
    <Label variant="category" as="h2">
      {children}
    </Label>
  );
};
