import { Article } from '@api/api';
// import { ArticleCard } from '@components/common/Card/ArticleCard';
import { DataFeed } from '@components/common/DataFeed';
import { useLatestStories } from '@queries/useLatestStories';
import { MEDIA_QUERIES, useMediaQuery } from '@student-coin/coinpaper-components';
import { buildArticleLink } from '@utils/link';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { FunctionComponent } from 'react';
import { Heading } from '../shared/Heading';
import { Section } from './LatestStories.styles';
import { LatestStoriesProps } from './LatestStories.types';

const ArticleCard = dynamic(
  () => import('@components/common/Card/ArticleCard').then((mod) => mod.ArticleCard),
  { ssr: true },
);

export const LatestStories: FunctionComponent<LatestStoriesProps> = ({ loadMoreText }) => {
  const {
    data = { pages: [] },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useLatestStories();
  const { t } = useTranslation('home');
  const isTabletWide = useMediaQuery(MEDIA_QUERIES.tablet);

  const onLoadMoreClick = () => {
    fetchNextPage();
  };

  return (
    <Section>
      <Heading>{t('latest')}</Heading>

      <DataFeed<Article>
        data={data.pages.map(({ items }) => items).flat()}
        hideHeader
        loading={isFetchingNextPage}
        loadMoreText={loadMoreText}
        loadMoreDisabled={isFetchingNextPage || !hasNextPage}
        onLoadMoreClick={onLoadMoreClick}
        renderData={(article: Article) => (
          <ArticleCard
            key={article.id}
            href={buildArticleLink(article.id, article.slug)}
            article={article}
            thumbnail={isTabletWide ? 'left' : 'top'}
            variant="large"
            withDescription={isTabletWide}
            descriptionLimit={isTabletWide ? 3 : 0}
            size={isTabletWide ? 'small' : 'large'}
          />
        )}
        variant="list"
      />
    </Section>
  );
};
