const yesterdayWas = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  .toISOString()
  .split('T')[0];

export const queries = {
  latestStories: {
    _sort: 'published_at:DESC',
    _where: [
      [
        {
          _or: [
            {
              featured: 'false',
            },
            {
              featured_null: 'true',
            },
          ],
        },
      ],
      [
        {
          _or: [
            {
              editors_pick: 'false',
            },
            {
              editors_pick_null: 'true',
            },
          ],
        },
      ],
      [
        {
          _or: [
            {
              hot: 'false',
            },
            {
              hot_null: 'true',
            },
          ],
        },
      ],
      [
        {
          generated_at_null: 'true',
        },
      ],
      [
        {
          _or: [
            {
              press_release: 'false',
            },
            {
              press_release_null: 'true',
            },
          ],
        },
      ],
    ],
  },
  todayArticles: {
    _where: [
      {
        _or: [{ published_date_gt: yesterdayWas }, { published_at_gt: yesterdayWas }],
      },
    ],
  },
  editorsPickArticles: {
    _where: {
      _or: [
        {
          featured: 'false',
        },
        {
          featured_null: 'true',
        },
      ],
      editors_pick: 'true',
    },
  },
};
