import tw, { styled } from 'twin.macro';
import { P, MEDIA_QUERIES } from '@student-coin/coinpaper-components';
import EmptySiteSVG from './emptySite.svg';

export const Container = styled.div`
  ${tw`container mx-auto px-4 lg:px-0 pb-4`};
`;

export const Header = styled.div`
  ${tw`mb-10`};
  &.header-container {
    margin: 0 0 50px 0;
    @media ${MEDIA_QUERIES.tablet} {
      margin: 0 0 10px 0;
    }
  }
`;

export const HeaderName = styled(P).attrs({ variant: 'medium' })`
  ${tw`font-semibold`};
`;

export const HeaderSubname = styled(P)`
  ${tw`text-2xl md:text-3xl font-bold`};
  &.subname {
    font-family: 'Poppins', sans-serif;
  }
`;

export const HeaderDescription = styled(P).attrs({ variant: 'large' })``;

const dataContainerCols = {
  list: tw`grid-cols-1 gap-3 md:gap-4`,
  grid: tw`md:grid-cols-2 lg:grid-cols-3 md:gap-10`,
  auto: `grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))`,
};

export const DataContainer = styled.div<{ variant: 'list' | 'grid' | 'auto' }>`
  ${tw`grid gap-4 grid-cols-1 md:gap-10`}
  ${({ variant }) => dataContainerCols[variant]};
`;

export const DataListDivider = styled.div`
  ${tw`border-t border-dashed my-1.5 md:my-2`};
  border-color: ${({ theme }) => theme.colors.title};
`;

export const LoadMoreContainer = styled.div`
  ${tw`w-full flex flex-col items-center md:flex-row md:justify-center mt-12 md:mt-10`}
  .btn-box {
    display: none;
    &.active {
      display: flex;
    }
  }
`;

export const Navigation = styled.div`
  ${tw`mb-10`}
`;

export const EmptySite = styled(EmptySiteSVG)``;

export const Text = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.title};
  margin-top: 1.5rem;

  @media ${MEDIA_QUERIES.tablet} {
    font-size: ${({ theme }) => theme.fontSize.large};
    line-height: 1.6rem;
    margin-right: 0.375rem;
  }
`;

export const Content = styled.div`
  ${tw`flex flex-col items-center`}
`;
