import styled from 'styled-components';
import { Label as Heading } from '../Heading/Heading.styles';

export const Section = styled.div<{ noBorder?: boolean }>`
  border-top: ${({ noBorder }) => (noBorder ? 'none' : '2px solid #000000')};
  padding-top: 0.5rem;

  ${Heading} {
    display: inline-block;
    padding-bottom: 1.5rem;
  }
`;
