import { articleApiClient } from '@api/clients';
import { useQuery, UseQueryResult } from 'react-query';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchArticlesCount = async (params?): Promise<unknown> => {
  const { data } = await articleApiClient.articlesCountGet({}, { params });
  return data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useArticlesCount = (params?): UseQueryResult<number> => {
  return useQuery(['articles-count', params], () => fetchArticlesCount(params));
};
