import { Article } from '@api/api';
import { articleApiClient } from '@api/clients';
import { queries } from '@consts/queries';
import { useArticlesCount } from '@queries/useArticlesCount';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

const limit = 6;
const startLimit = 12;

export const fetchLatestStories = async ({
  pageParam = 0,
}: {
  pageParam?: number;
}): Promise<{ page: number; items: Article[] }> => {
  const start = pageParam ?? 0;

  const { data } = await articleApiClient.articlesGet(
    { limit: start ? limit : startLimit, start },
    { params: queries.latestStories },
  );

  return { page: pageParam, items: data };
};

export const useLatestStories = (): UseInfiniteQueryResult<{ page: number; items: Article[] }> => {
  const { data: articleCount } = useArticlesCount(queries.latestStories);

  const totalPages = Math.ceil(articleCount / limit);

  return useInfiniteQuery('latest-stories', fetchLatestStories, {
    getNextPageParam: (lastPage, allPages) => {
      return allPages.length !== totalPages ? lastPage.page + limit : false;
    },
  });
};
