import tw, { styled } from 'twin.macro';
import { Container as DataFeed } from '@components/common/DataFeed/DataFeed.styles';
import { Section as SectionBase } from '../shared/Section';

export const Section = styled(SectionBase)`
  ${tw`col-span-3 lg:col-span-2`};

  ${DataFeed} {
    ${tw`px-0`};
  }
`;
