import React, { ReactElement } from 'react';
import Link from 'next/link';
import { NextLinkType } from './NextLink.types';

export const NextLink = ({
  href = '',
  target,
  name,
  icon,
  shallowNavigation = true,
}: NextLinkType): ReactElement => {
  return (
    <Link href={href} passHref shallow={shallowNavigation}>
      {/* NextLink takes care of passing href to anchor element */}
      {/* eslint-disable-next-line  */}
      <a target={target} rel="noopener">
        {name} {icon}
      </a>
    </Link>
  );
};
