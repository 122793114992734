import { Tabs } from '@components/common/Tabs';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import React, { ReactElement } from 'react';
import {
  Container,
  Content,
  DataContainer,
  DataListDivider,
  EmptySite,
  Header,
  HeaderDescription,
  HeaderName,
  HeaderSubname,
  LoadMoreContainer,
  Navigation,
  Text,
} from './DataFeed.styles';
import { DataFeedProps } from './DataFeed.types';

const Button = dynamic(
  () =>
    import('@components/common/Button').then<typeof import('@components/common/Button').Button>(
      (mod) => mod.Button,
    ),
  {
    ssr: false,
  },
);

export const EmptyMessage = (): ReactElement => {
  const { t } = useTranslation('empty-site');

  return (
    <Content>
      <EmptySite />
      <Text>{t('text')}</Text>
    </Content>
  );
};

export function DataFeed<TData extends { id: string }>({
  data,
  name,
  subname,
  description,
  loading,
  loadMoreDisabled = false,
  loadMoreText,
  onLoadMoreClick,
  renderData,
  activeNavigationItem,
  navigationItems,
  variant = 'grid',
  hideHeader = false,
  onActiveTabChange,
  shallowNavigation = false,
}: DataFeedProps<TData>): ReactElement {
  return (
    <Container>
      {!hideHeader && (
        <Header>
          <HeaderName>{name} /</HeaderName>
          <HeaderSubname as="h1">{subname}</HeaderSubname>
          {description && <HeaderDescription>{description}</HeaderDescription>}
        </Header>
      )}
      {navigationItems && (
        <Navigation>
          <Tabs
            items={navigationItems}
            activeTab={activeNavigationItem}
            onActiveTabChange={onActiveTabChange}
            shallowNavigation={shallowNavigation}
          />
        </Navigation>
      )}
      <DataContainer variant={variant}>
        {data?.map((entry, index) => {
          return (
            <React.Fragment key={entry?.id}>
              {renderData(entry)}
              {variant === 'list' && index !== data.length - 1 && <DataListDivider />}
            </React.Fragment>
          );
        })}
      </DataContainer>
      {loadMoreText && (
        <LoadMoreContainer>
          {data?.length > 0 ? (
            <div className={`btn-box ${!loadMoreDisabled ? 'active' : ''}`}>
              {!loadMoreDisabled ? (
                <Button
                  text={loadMoreText}
                  loading={loading}
                  onClick={onLoadMoreClick}
                  disabled={loadMoreDisabled}
                />
              ) : (
                ''
              )}
            </div>
          ) : (
            <EmptyMessage />
          )}
        </LoadMoreContainer>
      )}
    </Container>
  );
}
